import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.api.get.fetching = state.api.get.fetching;
  Object.assign(state, iState);
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value;
}

const STARTING = (state, value) => {
  state.api.post.starting = !!value;
}

const FINISHING = (state, value) => {
  state.api.post.finishing = !!value;
}

const FETCH_UPDATED = (state, data) => {
  let exams = data.data,
  currentFlag = false,
  allPassed = true,
  ret = [],
  index = 1;

  ret = exams.map(exam => {
    exam.index = index++;
    if(0 === exam.results.length){
      exam.passed = false;
    }

    for(let i = 0; i < exam.results.length; i++){
      let result = exam.results[i];
      exam.current = false;
      exam.passed = false;
      if(true === result.passed){
        exam.passed = true;
        break;
      }
    }

  	return exam;
  });


  for(let i = 0; i < ret.length; i++){
    let exam = ret[i];
    ret[i].current = false;

    if(false === exam.passed && false === currentFlag ){
      allPassed = false;
      ret[i].current = true;
      currentFlag = true;
    }
  }

  if(false === allPassed && false === currentFlag){
    ret[0].current = true;
  }

  state.allPassed = allPassed;
  state.api.get.values = ret;

}

export default {
  FETCHING,
  STARTING,  
  RESET_STATE,
  FETCH_UPDATED,
  FINISHING,
};
