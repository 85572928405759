import api from '../api'
import translationStore from '@/services/api/translation/store'
import fetchDelayed from '@/store/helper/fetchDelayed'

const fetch = (context, args, delay=500) => {
  let translations = context.rootGetters['services/api/translation/getTranslations'];
  let message = translations.loaders.exams;

  return fetchDelayed.fetch(
    api,
    context,
    message,
    args,
    delay
  );
}

const answer = ({ commit }, args) => {
  commit('SENDING_ANSWER', true);

  return api.submit(args)
  .then((response) => {
    commit('SENDING_ANSWER', false);
    commit('ADD_USER_ANSWER', args);

    return response;
  })
  .catch((error) => {
    commit('SENDING_ANSWER', false);
    return error.response;
  });
}

const review = ({ commit }, args) => {
  commit('SENDING_REVIEW', args);
  return api.review(args)
  .then(response => {
    commit('SENDING_REVIEW', false);
    return response;
  })
  .catch( error => {
    commit('SENDING_REVIEW', false);
    return error;
  });
}

const view = ({ commit }, args) => {
  commit('SENDING_VIEW', args);
  return api.view(args)
  .then(response => {
    commit('SENDING_VIEW', false);
    return response;
  })
  .catch( error => {
    commit('SENDING_VIEW', false);
    return error;
  });
}

export default {
  fetch,
  answer,
  view,
  review,
}
