import api from '../api'
import Store from '@/store'

const enroll = ({ commit }, args) => {
  commit('RESET');
  commit('FETCHING', true);

  return api.enroll(args)
    .then(response => {
      commit('SUCCESS', true);
      commit('FETCHING', false);
      Store.state.Auth.credits = response.data.credits.remaining
      return response;
    })
    .catch(error => {
      commit('SUCCESS', false);
      commit('FETCHING', false);

      commit('ERROR', true);

      return error;
    });
};

const redirect = ({ commit }, args) => {
  commit('RESET');
  commit('FETCHING', true);

  return api.redirect(args)
    .then(response => {
      window.location.href = process.env.VUE_APP_EDUCACIONIT + `inscripcion-alumni?contacto_id=${args.user_id}&calendario_id=${args.calendar_id}&token=${response.data.token}`
    })
    .catch(error => {
      commit('FETCHING', false);
      return error;
    });
};

const activate = ({ commit }, args) => {
  commit('RESET');
  commit('FETCHING', true);

  return api.activate(args)
    .then(response => {
      commit('SUCCESS', true);
      commit('FETCHING', false);

      return response;
    })
    .catch(error => {
      commit('SUCCESS', false);
      commit('FETCHING', false);

      commit('ERROR', true);

      return error;
    });
};

const reset = ({ commit }) =>{
  commit('RESET');
};

export default {
  enroll,
  redirect,
  reset,
  activate
}
