import colors from 'vuetify/es5/util/colors';

const theme = {
  appBg: colors.grey.lighten4,
  navHighlightedBg: colors.blue.lighten3,
  navHighlightedText: colors.grey.lighten4,
  navHighlightedHover: colors.blue.lighten1,
  selectedItem: colors.blue.lighten1,
  selectedItemText: colors.shades.white,
  loaderGradient1: '#eee',
  loaderGradient2: '#ddd',
  loaderGradient3: '#eee',
  primary: colors.blue.darken1,
  success: colors.green.base,
  error: colors.red.darken2,
  warning: colors.orange.base,
  accent: colors.blue.lighten4,
  cardBg: colors.shades.white,
  cardHoverBg: '#eee',
  cardHoverText: colors.grey.lighten4,
  cardIconText: colors.blue.darken3,
  border: colors.grey.lighten3,
  toolbarBg: colors.blue.darken2,
  toolbarText: colors.shades.black,
  chatPanelBg: colors.shades.white,
  chatHeaderBg: colors.grey.lighten3,
  chatEntryHoverBg: colors.grey.lighten1,
  chatEntryUnreadBg: colors.blue.lighten4,
  chatEntryUnreadText: colors.grey.darken2,
  chatEntrySelectedBg: colors.grey.darken4,
  chatEntryDisconnectedBg: colors.grey.darken4,
  chatEntryConnectedBg: colors.blue.darken2,
  chatInputContainerBg: colors.grey.lighten2,
  chatInputBg: colors.grey.lighten3,
  chatInputText: colors.grey.lighten4,
  chatInputScroll: colors.grey.lighten3,
  chatEntryBg: colors.grey.darken4,
  chatSearchBorder: colors.grey.darken4,
  chatSearchBg: colors.grey.darken4,
  chatSearchText: colors.grey.lighten3,
  chatMessageHisBg: colors.blue.lighten2,
  chatMessageHisText: colors.shades.white,
  chatMessageMineBg: colors.blue.lighten3,
  chatMessageMineText: colors.shades.white,
  chatMessageDate: colors.grey.darken3,
  courseOnlineNextClassBg: colors.blue.lighten4,
  courseOnlineClassAfterNextBg: colors.grey.lighten3,
  courseOnlineClassSeparator: colors.grey.lighten2,
  swiperNotCurrentClass: colors.grey.lighten2,
  examStatusBar: colors.grey.lighten3,
  timerNormal: colors.shades.black,
  timerWarning: colors.orange.darken1,
  timerDanger: colors.red.base,
  href: colors.grey.darken1,
};

export default theme;
