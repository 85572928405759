import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const achievement = args => {
  let params = new URLSearchParams();
  params.append('career', args.career);
  return Api.get(endpoints.get('get')+'?'+params.toString());
};

export default {
  achievement
};
