import 'material-design-icons-iconfont-only/iconfont/material-icons.css';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import 'vuetify/dist/vuetify.min.css';
import '@/assets/css/app.css';

import Vue from 'vue';
import Vuetify from 'vuetify';
import dayjs from '@/plugins/dayjs';
import markdown from '@/plugins/markdown';
// TODO: remove lazy load an image lightbox
// only used for vue-image-lightbox
import VueLazyLoad from 'vue-lazyload';

import itComponents from '@educacionit/it-components';
import store from './store';
import router from './router';
import App from './App.vue';

window.Hls = require('hls.js');

Vue.use(VueLazyLoad);

Vue.use(itComponents);

Vue.use(Vuetify, { options: { customProperties: true } });
Vue.use(dayjs);
Vue.use(markdown);

import {initializeApp} from './store/modules/Main';

(async () => {
  await initializeApp('es');
  document.fonts.ready.then(() => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  });
})();
