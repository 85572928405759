export default {
  get: function(){
    return {
      viewed: false,
      rate:{
        error: false,
        sending: false,
        data: {}
      },
      read:{
        sending: false,
        data: {}
      },
      api:{
        get:{
          fetching: false,
          error: false,
          values: [],
        },
        put: {

        },
        delete:{

        }
      }
    }
  }
}
