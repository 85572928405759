import initialState from "./state.js"
import { $dayjs } from "@/plugins/dayjs"

const RESET_STATE = state => {
  const iState = initialState.get()
  //Preserve fetching state
  iState.api.get.fetching = state.api.get.fetching
  Object.assign(state, iState)
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value
}

const FETCH_UPDATED = (state, data) => {
  const attempt = data.data,
    questions = attempt.questions
  // result = attempt.result,
  // comment = attempt.comment
  state.exam = attempt.exam
  state.course = attempt.course
  state.result = attempt.result
  state.comment = attempt.comment
  state.api.get.values = attempt

  state.result.time = $dayjs
    .utc(
      $dayjs(state.result.date.end, "DD-MM-YYYY HH:mm:ss").diff($dayjs(state.result.date.start, "DD-MM-YYYY HH:mm:ss"))
    )
    .format("HH:mm:ss")

  state.questions = questions.map(question => {
    question.answers.map(answer => {
      question.userAnswers.map(uAnswer => {
        if (true === answer.checked) {
          return
        }

        return (answer.checked = uAnswer === answer.id)
      })

      return answer
    })

    return question
  })
}

const RATE_SENDING = (state, value) => {
  state.api.post.rate.sending = !!value
}

const RATE_SUCCESS = (state, data) => {
  state.comment = data
  state.api.post.rate.data = data
}

export default {
  FETCHING,
  RESET_STATE,
  FETCH_UPDATED,
  RATE_SENDING,
  RATE_SUCCESS
}
