const SUPPORTED = (state, args) => {
  state.supported = !!args;
}

const GRANTED = (state, args) => {
  state.granted = args;
}

const ADD = (state, args) => {
  state.notifications.push(args);
}

const ENABLE = (state, args) => {
  state.enable = !!args;
}

export default {
  SUPPORTED,
  GRANTED,
  ADD,
  ENABLE
}
