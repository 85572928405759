import initialState from './state.js'

const FETCH_UPDATED = (state, data) => {
  state.api.get.values = data.data;
  state.posts = data.data.posts;
  state.config = data.data.config;
};

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.api.get.fetching = state.api.get.fetching;
  Object.assign(state, initialState);
}

const POST_SENDING = (state, value) => {
	state.api.post.add.sending = !!value;
}

const POST_ADD = (state, value) => {
  state.posts.unshift(value);
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value;
}

const POST_ADD_WAIT = (state, value) => {
	state.api.post.add.wait = parseInt(value);
}

export default {
  FETCH_UPDATED,
  FETCHING,
  RESET_STATE,
  POST_SENDING,
  POST_ADD,
  POST_ADD_WAIT,
};
