import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const mark = (args) => {
  let params = new URLSearchParams();

  params.append('recording', args.recording);

  return Api.get(endpoints.get('mark')+'?'+params.toString());
}

export default {
  mark,
};
