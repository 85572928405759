import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();

  params.append('code', args.code);

  //There is no need to make an XHR call, diplomas are of type HTML or PDF.
  return Api.get(endpoints.get('get')+'?'+params.toString());
}

export default {
  fetch,
};
