import api from '../api'
import fetchDelayed from '@/store/helper/fetchDelayed'

const fetch = (context, args, delay=500) => {
  let translations = context.rootGetters['services/api/translation/getTranslations'];
  let message = translations.loaders.course_classes;

  return fetchDelayed.fetch(
    api,
    context,
    message,
    args,
    delay
  );
}

export default {
  fetch,
}
