
export default {
  isFetching(state){
    return state.api.get.fetching;
  },
  get(state){
    return state.api.get.values;
  },
  allPassed(state){
    return state.allPassed;
  },
  isStarting(state){
  	return state.api.post.starting;
  },
  isFinishing(state){
  	return state.api.post.finishing;
  }
}
