export default {
  get: function(){
    return{
      current:{},
      allPassed: false,
      api:{
        get:{
          fetching: false,
          error: false,
          values: [],
        },
        put: {

        },
        post:{
          starting: false,
          finishing: false,
        },
        delete:{

        }
      }
    }
  }
}
