export default {
  get: function(){
    return {
      config: {},
      posts: [],
      api:{
        get:{
          fetching: null,
          error: false,
          values: {}
        },
        put: {

        },
        post:{
          add:{
            sending: false,
            data: {},
            wait: 0
          }
        },
        delete:{

        }
      }
    }
  }
}
