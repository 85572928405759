/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();

ENDPOINT.set('github', '/plugin/benefit/github');
ENDPOINT.set('send', '/plugin/benefit/send');
ENDPOINT.set('generatePdf', '/plugin/benefit/generate');

export default ENDPOINT
