const github = state => state.get.github;
const success = state => state.get.success;
const fetching = state => state.get.fetching;
const error = state => state.get.error;

export default {
  github,
  success,
  fetching,
  error,
}
