import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();

  params.append('achievement', args.achievement);
  params.append('user', args.user);

//There is no need to make an XHR call, diplomas are of type HTML or PDF.
  return Api.get(endpoints.get('get')+'?'+params.toString());
};

const certificates = (args) => {
  let params = new URLSearchParams();

  params.append('user', args.user);

//There is no need to make an XHR call, diplomas are of type HTML or PDF.
  return Api.get(endpoints.get('certificates')+'?'+params.toString());
};

export default {
  fetch,
  certificates
};
