import api from '../api'

const github = ({ commit }) => {
  commit('RESET_STATE');
  commit('FETCHING', true);

  return api.github()
    .then(response => {
      commit('FETCH_SUCCESS', true);
      commit('FETCHING', false);
      commit('SET_GITHUB_BENEFIT', response.data);
      return response;
    })
    .catch(error => {
      commit('FETCH_SUCCESS', false);
      commit('FETCHING', false);
      commit('FETCH_ERROR', error);
      return error;
    });
};

const send = ({commit}, args) => {
  return api.send(args)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
};

const generatePdf = ({ commit }) => {
  commit('RESET_STATE');
  commit('FETCHING', true);

  return api.generatePdf()
    .then(response => {
      commit('FETCH_SUCCESS', true);
      commit('FETCHING', false);
      return response;
    })
    .catch(error => {
      commit('FETCH_SUCCESS', false);
      commit('FETCHING', false);
      commit('FETCH_ERROR', error);
      return error;
    });
};

export default {
  github,
  send,
  generatePdf
}
