import myCache from './cache';
import storeCache from '@/services/api/cache/localStorage'

export default {

  get: function(){
    let cachedState = storeCache.get(myCache.KEY),
      defaults = {
        darkMode: false
      };

    return { ...defaults, ...cachedState}
  }
}
