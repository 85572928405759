/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();

ENDPOINT.set('get', '/exam/user/get');
ENDPOINT.set('start', '/exam/start');
ENDPOINT.set('finish', '/exam/finish');
ENDPOINT.set('results', '/exam/result');

export default ENDPOINT