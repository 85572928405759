import api from "../api"
import fetchDelayed from "@/store/helper/fetchDelayed"

const fetch = (context, args, delay = 500) => {
  const translations = context.rootGetters["services/api/translation/getTranslations"]
  const message = translations.loaders.exams

  return fetchDelayed.fetch(api, context, message, args, delay)
}

const rate = ({ commit }, args, delay = 1000) => {
  commit("RATE_SENDING", true)

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return api
        .rate(args)
        .then(response => {
          commit("RATE_SENDING", false)
          commit("RATE_SUCCESS", response.data)

          return resolve(response.data)
        })
        .catch(error => {
          commit("RATE_SENDING", false)
          console.error(error)

          return reject(error)
        })
    }, delay)
  })
}

export default {
  fetch,
  rate
}
