import _ from 'underscore'

export default {
  isFetching(state){
    return state.api.get.fetching;
  },
  get(state){
    return state.api.get.values;
  },
  getExam(state){
    return state.exam;
  },  
  getCourse(state){
    return state.course;
  },
  getResult(state){
    return state.result;
  },
  getComment(state){
    return state.comment;
  },    
  getQuestions(state){
  	return state.questions;
  },
  isBeingRated(state){
    return state.api.post.rate.sending;
  },
  isRated(state){
    return false === _.isEmpty(state.comment);
  }
}
