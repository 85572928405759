/**
 * Hide sidebar
 *
 * @param commit
 * @param args
 * @returns {Promise<any>}
 */
const collapse = ({commit}, args) => {
  return new Promise((resolve, reject) => {
    commit('SIDEBAR_COLLAPSE', args);
    resolve(args);
  })

}

export default {
  collapse
}
