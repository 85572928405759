import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();

  params.append('course_code', args.course_code);

  return Api.get(endpoints.get('get')+'?'+params.toString());
}

const abandonCalendar = (args) => {
  let params = new URLSearchParams();

  params.append('calendar', args.calendar);

  return Api.post(endpoints.get('leave'), params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export default {
  fetch,
  abandonCalendar
};
