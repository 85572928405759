import initialState from './state.js'

const SET_CLASSES = (state, data) => {
  /**
   * Convert date entries to date objects
   */
  state.classes = data;
}

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
}

const ERROR = (state, error) => {
  state.error = error;
}

const FETCHING = (state, value) => {
  state.fetching = !!value;
}

export default {
  FETCHING,
  SET_CLASSES,
  ERROR,
  RESET_STATE,
};
