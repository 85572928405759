import initialState from './state.js'

const RESET = state => {
  let iState = initialState.get();
  Object.assign(state, iState);
};

const FETCHING = (state, value) => {
  state.fetching = !!value;
};

const SUCCESS = (state, value) => {
  state.success = !!value;
};

const ERROR = (state, value) => {
  state.error = value;
};

export default {
  FETCHING,
  SUCCESS,
  ERROR,
  RESET
};
