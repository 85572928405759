import api from '../api'
import fetchDelayed from '@/store/helper/fetchDelayed'
import _ from 'underscore'
import Store from '@/store'

const fetch = (context, args, delay=500) => {
  let translations = context.rootGetters['services/api/translation/getTranslations'];
  let message = translations.loaders.exams;

  if(_.has(args, 'delay')){
    delay = args.delay;
    delete args.delay;
  }

  return fetchDelayed.fetch(
    api,
    context,
    message,
    args,
    delay
  );
}

const start = ({ commit }, args, delay=500) => {

  commit('STARTING', true);

  return api.start(args)
    .then((response) => {
      commit('STARTING', false);

      Store.state.Auth.exam = response.data
      return response.data;
  })
  .catch((error) => {
    commit('STARTING', false);
    return error;
  });
}

const finish = ({ commit }, args, delay=500) => {
  commit('FINISHING', true);

  if(_.has(args, 'delay')){
    delay = parseInt(args.delay);
    delete(args.delay);
  }

  return new Promise((resolve, reject) => {
    setTimeout( function() {
      return api.finish(args)
      .then(response => {
        commit('FINISHING', false);
        Store.state.Auth.exam = null
        Store.state.Auth.credits += response.data.credits
        return resolve(response.data);
      })
      .catch(error => {
        commit('FINISHING', false);
        return reject(error);
      });
    }, delay);
  });
}

export default {
  fetch,
  start,
  finish
}
