import Api from "@/services/api/api/"

const fetch = args => {
	const params = new URLSearchParams()

	params.append("id", args.resource.id)
	params.append("class", args.classData.id)

	return Api.get(`/class/resource/get?${params.toString()}`)
}

const markReaded = args => {
	const options = {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		}
	}
	const params = new URLSearchParams()

	params.append("class", args.class.id)
	params.append("resource", args.resource.id)
	params.append("value", args.value ? 1 : 0)
	//console.log({ args })
	return Api.post("/class/resource/view", params, options)
}

const rate = args => {
	const options = {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		}
	}
	const params = new URLSearchParams()

	params.append("class", args.class.id)
	params.append("resource", args.resource.id)
	params.append("comment", args.comment)
	params.append("rating", args.rating)

	return Api.post("/class/resource/rate", params, options)
}

export default {
	fetch,
	markReaded,
	rate
}
