import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const details = () => {
  return Api.get(endpoints.get('details'));
};

export default {
  details
};
