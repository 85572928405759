const achievements = state => state.achievements;
const success = state => state.success;
const fetching = state => state.fetching;
const error = state => state.error;

export default {
  achievements,
  success,
  fetching,
  error,
}
