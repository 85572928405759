import api from '../api'

const requestMerge = ({commit}, data) => {
  commit('REQUEST_MERGE_SENDING', true);

  return new Promise((resolve, reject) => {
    api.requestMerge(data)
      .then(response => {
        commit('REQUEST_MERGE_SENDING', false);
        commit('REQUEST_MERGE_SUCCESS', true);

        resolve(response);
      })
      .catch(error => {

        commit('REQUEST_MERGE_SENDING', false);
        commit('REQUEST_MERGE_SUCCESS', false);
        commit('REQUEST_MERGE_ERRORS', error.response.data.errors);

        reject(error);
      })
  });
};

const fetch = ({commit}, data) => {
  commit('REQUEST_MERGE_SENDING', true);

  return new Promise((resolve, reject) => {
    api.fetch(data)
      .then(response => {
        commit('REQUEST_MERGE_SENDING', false);
        commit('REQUEST_MERGE_SUCCESS', true);
        commit('REQUEST_MERGE_DATA', response.data);
        resolve(response);
      })
      .catch(error => {
        commit('REQUEST_MERGE_SENDING', false);
        commit('REQUEST_MERGE_SUCCESS', false);

        reject(error);
      })
  });
};

const merge = ({commit}, data) => {
  commit('MERGE_SENDING', true);

  return new Promise((resolve, reject) => {
    api.merge(data)
      .then(response => {
        commit('MERGE_SENDING', false);
        commit('MERGE_SUCCESS', true);
        resolve(response);
      })
      .catch(error => {
        commit('MERGE_SENDING', false);
        commit('MERGE_SUCCESS', false);

        reject(error);
      })
  });
};

export default {
  requestMerge,
  fetch,
  merge
}
