import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('attempt', args.attempt);

  return Api.get(endpoints.get('get')+'?'+params.toString());
}

/**
 * Rate a resource
 */
const rate = (args) => {
	let options = {
		headers:{
    		'Content-Type': 'application/x-www-form-urlencoded'
    	}
    },
	params = new URLSearchParams();

	params.append('attempt', args.attempt);
	params.append('comment', args.comment);
	params.append('rating', args.rating);

	return Api.post(
		endpoints.get('rate'),
		params,
		options
	);
}

export default {
  fetch,
  rate,
};
