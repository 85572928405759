import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = args => {
  let params = new URLSearchParams();
  params.append('course_code', args.course_code);
  params.append('calendar_id', args.calendar_id);
  return Api.get(endpoints.get('get')+'?'+params.toString());
};

export default {
  fetch
};
