import Api from '@/services/api/api/index.js'
import endPoints from './endpoint'

const fetch = () => {
  return Api.get(endPoints.get('get'));
}

export default {
  fetch,
};
