import MarkdownIt from 'markdown-it';

export default {
  install: function(Vue) {
    const defaultOptions = {
      linkify: false,
      breaks: false,
      html: false,
    };

    Vue.prototype.$md = function(text, customOptions = {}) {
      const options = Object.assign({}, defaultOptions, customOptions);
      const md = new MarkdownIt(options)
      return md.render(text);
    };
  }
}
