<template>
  <v-app :dark="false">
    <component :is="layout">
      <the-no-connection-modal />
      <router-view />
      <the-snackbar-loader />
    </component>
  </v-app>
</template>

<script>
import Vue from 'vue';

import TheNoConnectionModal from '@/components/TheNoConnectionModal';
import TheSnackbarLoader from '@/components/TheSnackbarLoader';

import lightTheme from '@/theme/light';

export default {
  name: 'App',
  components: {
    TheNoConnectionModal,
    TheSnackbarLoader,
  },
  data() {
    return {
      layout: 'div',
    };
  },
  computed: {
    authenticated() {
      return this.$store.state.Auth.authenticated;
    },
    user() {
      return this.$store.state.Auth.user;
    },
  },

  watch: {
    authenticated(n) {
      if (!n) return;
      // Obtain server date and start timer to have an accurate client date
      this.$store.dispatch('services/api/server/date/fetch');
      // Keep the session alive
      this.$store.dispatch('AuthFirebase/keepAlive', {interval: 30});
    },
    async user(n) {
      if (!n.userId) return;
      await this.$store.dispatch('Auth/fetchCertificates', n.userId);
    },
    // TODO: remove when all pages have layout
    $route: {
      deep: true,
      handler() {
        // this.$nextTick(() => {
        this.setLayout();
        // })
      },
    },
  },
  mounted() {
    const preloader = document.querySelector('#app--preloader');

    this.$vuetify.theme = lightTheme;

    if (preloader) preloader.remove();

    // Init browser window events (Checks for viewport resize / detects mobile client)
    this.$store.dispatch('services/browser/window/init');
  },

  methods: {
    setLayout() {
      const { layout } = this.$route.meta;
      if (layout) {
        if (!Vue.options.components[this.name]) Vue.component(layout, () => import(`@/layouts/${layout}.vue`));
        this.layout = layout;
      } else if (this.layout !== 'div') this.layout = 'div';
    },
  },
};
</script>
