import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const github = () => {
  return Api.get(endpoints.get('github'));
};

const send = args => {
  let params = new URLSearchParams();

  params.append('name', args.benefit.name);
  params.append('type', args.benefit.type);

  Object.keys(args.fields).map(item => {
    params.append('fields['+item+']', args.fields[item]);
  });

  return Api.post(endpoints.get('send'), params,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const generatePdf = () => {
  return Api.get(endpoints.get('generatePdf'), { responseType: 'blob' });
};

export default {
  github,
  send,
  generatePdf
};
