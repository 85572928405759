import api from "@/services/api/api"
import storeCache from "@/services/api/cache"

const initialState = () => ({
  api: {
    get: {
      fetching: false,
      error: false,
      lang: "",
      values: {}
    },
    put: {},
    post: {},
    delete: {}
  }
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getTranslations(state) {
      return state.api.get.values
    },

    getCurrentTranslationLanguage(state) {
      return state.api.get.lang
    },

    getTranslationsFromCache() {
      const cache = storeCache.get("app/translations")

      if (!cache) throw "Translations cache not found!"

      return cache.api.get.values
    }
  },
  mutations: {
    FETCHING(state, value) {
      state.api.get.fetching = !!value
    },

    FETCH_COMMIT(state, translations) {
      state.api.get.lang = translations.lang
      state.api.get.values = translations.values
      storeCache.save("app/translations", state)
    },

    FETCH_ERROR(state, value) {
      state.api.get.error = value
    }
  },
  actions: {
    fetchTranslations({ commit }, lang) {
      commit("FETCHING", true)

      let uri = "/translation/get"
      const params = new URLSearchParams()

      if (lang) params.append("lang", lang)

      uri += Array.from(params).length > 0 ? "?" + params.toString() : ""

      return api
        .get(uri)
        .then(response => {
          commit("FETCHING", false)
          commit(response.data.success ? "FETCH_COMMIT" : "FETCH_ERROR", response.data)

          return response.data
        })
        .catch(error => {
          commit("FETCHING", false)
          commit("FETCH_ERROR", error)
          return error
        })
    }
  }
}
