const supported = state => state.supported;
const granted = state => state.granted;
const notifications = state => state.notifications;

const last = state => {
  let length = state.notifications.length - 1;

  if(length < 0){
    return false;
  }

  return state.notifications[state.notifications.length - 1];
}

export default {
  supported,
  granted,
  notifications,
  last
};
