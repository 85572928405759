
export default {
  isFetching(state){
    return state.api.get.fetching;
  },
  getPosts(state){
    return state.posts;
  },
  getConfig(state){
    return state.config;
  },
  adding(state){
  	return state.api.post.add.sending;
  },
  isPostingTooFast(state){
  	return state.api.post.add.wait > 0;
  }
}
