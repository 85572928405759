export default {
  get: function(){
    return {
      fetching: false,
      error: false,
      success: false,
      achievements: [],
    }
  }
}
