import api from '../api'

const fetch = (context, args, delay=500) => {
  let commit = context.commit;
  
  commit('FETCHING', true);

  return api.fetch(args)
  .then(response => {
    commit('FETCH_UPDATED', response.data);
    commit('FETCHING', false);

    return response.data;
  })
  .catch(error => {
    commit('FETCHING', false);
    return error;
  })

}

export default {
  fetch,
}
