const values = state => state.values;
const fetching = state => state.fetching;
const error = state => state.error;

const current = state => {
  let values = state.values,
    _default = {};

  if(values.length === 0) return _default;

  _default = values[0];

  for(let i = 0; i < values.length; i++){
    if(true === values[i].current) return values[i];
  }

  return _default;
}

export default {
  values,
  fetching,
  error,
  current
}
