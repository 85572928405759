import courseService from "@/services/api/course/course"
import courseCalendarService from "@/services/api/course/calendar"
import currentCoursesService from "@/services/api/course/current"
import staleCoursesService from "@/services/api/course/stale"
import unifiedCoursesService from "@/services/api/course/unified"
import courseClassService from "@/services/api/course/class"
import courseSuggestionService from "@/services/api/course/suggestion"
import testimonialService from "@/services/api/course/testimonial"
import enrollmentService from "@/services/api/course/enrollment"
import relatedCourseService from "@/services/api/course/related"
import creditsService from "@/services/api/course/credits"

export default {
  namespaced: true,
  modules: {
    course: courseService,
    calendar: courseCalendarService,
    class: courseClassService,
    current: currentCoursesService,
    stale: staleCoursesService,
    unified: unifiedCoursesService,
    suggestion: courseSuggestionService,
    testimonial: testimonialService,
    enrollment: enrollmentService,
    related: relatedCourseService,
    credits: creditsService
  }
}
