const ADAPTER = sessionStorage

const get = key => {
  const cache = JSON.parse(ADAPTER.getItem(key))
  return cache ? cache : {}
}

const getItem = key => {
  return ADAPTER.getItem(key)
}

const save = (key, state) => {
  const clone = JSON.stringify(state)
  ADAPTER.setItem(key, clone)
  Object.assign(state, JSON.parse(clone))
}

const saveItem = (key, value) => {
  ADAPTER.setItem(key, value)
}

const remove = key => {
  ADAPTER.removeItem(key)
}

const drop = () => {
  return ADAPTER.clear()
}

export default {
  get,
  getItem,
  save,
  saveItem,
  remove,
  drop
}
