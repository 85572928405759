import MyCache from "./cache"
import StoreCache from "@/services/api/cache"

const SET_CURRENT = (state, value) => {
  const cache = StoreCache.get(MyCache.STORAGE_KEY)
  cache.current = value
  state.current = value
  StoreCache.save(MyCache.STORAGE_KEY, cache)
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value
}

const FETCH_COMMIT = (state, languages) => {
  state.api.get.values = languages
}

const FETCH_ERROR = (state, value) => {
  state.api.get.error = value
}

export default {
  FETCHING,
  FETCH_COMMIT,
  FETCH_ERROR,
  SET_CURRENT
}
