import Api from '@/services/api/api/index.js'
import endPoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('calendar_id', args.calendar_id);

  return Api.get(endPoints.get('fetch')+'?'+params.toString());
}

const answer = (args) => {
	let options = {
		headers:{
    		'Content-Type': 'application/x-www-form-urlencoded'
    	}
    },
    params = new URLSearchParams();

    args.question.map((question, index)=>{
      params.append('question['+index+']', question);
    });

    params.append('survey', args.survey);
    params.append('calendar_id', args.calendar_id);
    params.append('force', 1);

	return Api.post(
		endPoints.get('answer'),
		params,
		options
	);
}

export default {
  fetch,
  answer
};
