/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();

ENDPOINT.set('requestMerge', '/student/request/merge');
ENDPOINT.set('fetch', '/student/get/merge');
ENDPOINT.set('merge', '/student/merge');

export default ENDPOINT
