
export default {
  isFetching(state){
    return state.api.get.fetching;
  },
  getData(state){
    return state.api.get.values;
  },
  getImage(state){
    return state.png;
  },
  getPDF(state){
    return state.pdf;
  },
  getHtml(state){
    return state.html;
  },
  getPng64(state){
    return state.png64;
  },
  getCourse(state){
    return state.course;
  },
  getBranchName(state){
    return state.branch.name;
  },
  getBranchLocation(state){
    return state.branch.location;
  }
}
