import Api from '@/services/api/api/index.js'
import endPoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('course_code', args.course_code);

  return Api.get(endPoints.get('courseClasses')+'?'+params.toString());
}

const markViewed = (args) => {
  let params = new URLSearchParams(),
  headers = {
   	 headers:{
   	   'Content-Type': 'application/x-www-form-urlencoded'
   	 }
  };

  params.append('id', args.id);

  return Api.post(endPoints.get('markViewed'), params, headers);
}


export default {
  fetch,
  markViewed,
};
