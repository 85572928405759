import recordingStore from './recording';
import scheduleStore from './schedule';

export default {
  namespaced: true,
  modules: {
    recording: recordingStore,
    schedule: scheduleStore
  }
}
