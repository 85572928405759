import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.api.get.fetching = state.api.get.fetching;
  Object.assign(state, iState);
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value;
}

const SENDING_ANSWER = (state, value) => {
  state.api.post.answering = !!value;
}

const SENDING_REVIEW = (state, value) => {
  state.api.post.reviewing = !!value;
}

const SENDING_VIEW = (state, value) => {
  state.api.post.viewing = !!value;
}

const ADD_USER_ANSWER = (state, value) => {
  for(let i = 0; i < state.questions.length; i++){
    let question = state.questions[i];
    if(question.assignedId === value.question){
      return question.userAnswers = value.answer;
    }
  }

  throw ['No question found to add a user answer with values',value].join('');
}

const FETCH_UPDATED = (state, data) => {
  let questions = data.data.questions;

  state.questions = questions.map((question) => {

    question.answers.map((answer) => {
      question.userAnswers.map((uAnswer) => {
        if(true === answer.checked){
          return;
        }

        return answer.checked = uAnswer.value === answer.id;
      });

      return answer;
    })

    return question;
  });

  state.examConfig = data.data.config;

  state.api.get.values = data;
}

export default {
  FETCHING,
  RESET_STATE,
  FETCH_UPDATED,
  SENDING_ANSWER,
  SENDING_VIEW,
  SENDING_REVIEW,
  ADD_USER_ANSWER,
};
