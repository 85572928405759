import api from '../api'

const fetch = (context, args, delay=500) => {
  let commit = context.commit;

  commit('FETCHING', true);
  commit('CERTIFIED', false);

  return api.fetch(args)
  .then(response => {
    commit('FETCH_UPDATED', response.data);
    commit('FETCHING', false);
    commit('CERTIFIED', true);
    return response;
  })
  .catch(error => {
    commit('FETCHING', false);
    return error;
  })

};

const certificates = (context, args, delay=500) => {
  let commit = context.commit;

  commit('FETCHING', true);

  return api.certificates(args)
    .then(response => {
      commit('SET_USER_CERTIFICATES', response.data);
      commit('FETCHING', false);
      return response;
    })
    .catch(error => {
      commit('FETCHING', false);
      return error;
    })

}

export default {
  fetch,
  certificates
}
