import api from "@/services/api/api"

const initialState = () => ({
  fetching: null,
  success: null,
  error: null,
  date: null,
  now: null, //Local clock synchronized through setInterval,
  interval: null
})
export default {
  namespaced: true,
  state: initialState(),
  getters: {
    date(state) {
      return state.date
    },
    success(state) {
      return state.success
    },
    fetching(state) {
      return state.fetching
    },
    now(state) {
      return state.now
    },
    error(state) {
      return state.error
    }
  },

  mutations: {
    RESET_STATE(state) {
      const iState = initialState()
      //Preserve fetching state
      iState.fetching = state.fetching
      Object.assign(state, iState)
    },

    FETCHING(state, value) {
      state.fetching = !!value
    },

    FETCH_SUCCESS(state, value) {
      state.success = !!value
    },

    FETCH_ERROR(state, value) {
      state.error = value
    },

    CLEAR_INTERVAL(state) {
      clearInterval(state.interval)
      state.interval = null
    },

    SET_SERVER_DATE(state, value) {
      // let flag = false
      if (state.interval) {
        clearInterval(state.interval)
        state.interval = null
      }

      state.date = value.date
      state.now = value.date
      state.interval = setInterval(() => {
        const date = state.now instanceof Date ? state.now : new Date(state.now)

        if (!date) {
          clearInterval(state.interval)
          state.interval = null
          return
        }

        date.setTime(date.getTime() + 500)

        state.now = date

        // flag = true
      }, 500)
    }
  },
  actions: {
    async fetch({ commit }) {
      commit("RESET_STATE")
      commit("FETCHING", true)
      try {
        const res = api.get("/server/date")
        commit("FETCH_SUCCESS", true)
        commit("FETCHING", false)
        commit("SET_SERVER_DATE", res.data)
        return res
      } catch (err) {
        commit("FETCH_SUCCESS", false)
        commit("FETCHING", false)
        commit("FETCH_ERROR", err)
        return err
      }
    }
  }
}
