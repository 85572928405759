import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  Object.assign(state, iState);
};

const REQUEST_MERGE_SENDING = (state, value) => {
  state.request.sending = !!value;
}

const REQUEST_MERGE_SUCCESS = (state, value) => {
  state.request.success = !!value;
}

const REQUEST_MERGE_ERRORS = (state, value) => {
  state.request.errors = value;
}

const MERGE_SENDING = (state, value) => {
  state.merge.sending = !!value;
}

const MERGE_SUCCESS = (state, value) => {
  state.merge.success = !!value;
}

const MERGE_ERROR = (state, value) => {
  state.merge.error = value;
}

const REQUEST_MERGE_DATA = (state, value) => {
  state.users = value;
}

export default {
  REQUEST_MERGE_SENDING,
  REQUEST_MERGE_SUCCESS,
  REQUEST_MERGE_ERRORS,
  MERGE_SENDING,
  MERGE_SUCCESS,
  MERGE_ERROR,
  REQUEST_MERGE_DATA
};
