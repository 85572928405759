import initialState from './state.js'

const FETCH_UPDATED = (state, data) => {
  state.values = data.data;
};

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
}

const FETCHING = (state, value) => {
  state.fetching = !!value;
}

const SET_CURRENT_CLASS = (state, current) => {
  let classes = state.values;

  for(let i = 0; i < classes.length; i++){
    classes[i].current = current.id === classes[i].id;
  }
}

const MARK_VIEWED_SENDING = (state, value) => {
  state.view.sending = !!value;
}

const MARK_VIEWED_SUCCESS = (state, data) => {
  state.view.data = data;
}

export default {
  SET_CURRENT_CLASS,
  FETCH_UPDATED,
  FETCHING,
  RESET_STATE,
  MARK_VIEWED_SENDING,
  MARK_VIEWED_SUCCESS,
};
