import api from "@/services/api/api/index.js"

const initialState = {
  get: () => {
    return {
      api: {
        get: {
          fetching: null,
          error: false,
          values: []
        },
        put: {},
        post: {},
        delete: {}
      }
    }
  }
}

export default {
  namespaced: true,
  state: initialState.get(),
  getters: {
    courses(state) {
      return state.api.get.values
    },
    fetching(state) {
      return state.api.get.fetching
    }
  },
  mutations: {
    FETCH_UPDATED(state, data) {
      state.api.get.values = data
    },

    RESET_STATE(state) {
      const iState = initialState.get()
      state.api.get.values = []
      //Preserve fetching state
      iState.api.get.fetching = state.api.get.fetching
      Object.assign(state, initialState)
    },

    FETCHING(state, value) {
      state.api.get.fetching = !!value
    }
  },
  actions: {
    async fetch({ commit }) {
      try {
        const { data } = await api.get("/course/get/unified")
        commit("FETCH_UPDATED", data)
        return data
      } catch (err) {
        console.log({ err })
      }
    },

    reset({ commit }) {
      commit("RESET_STATE")
    }
  }
}
