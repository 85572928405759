import apiService from '@/services/api/api/store';
// import authenticationService from "@/Auth/store"
import benefitService from '@/services/api/benefit';
import courseService from '@/services/api/course';
import departmentService from '@/services/api/department';
import examService from '@/services/api/exam';
import languageService from '@/services/api/language/store';
import router from '@/services/api/router';
import translationService from '@/services/api/translation/store';
import localService from '@/services/api/local';
import serverService from '@/services/api/server';
import recoveryService from '@/services/api/recovery';
import achievementService from '@/services/api/achievement';
import mergerService from '@/services/api/merger';
import bootcampService from '@/services/api/bootcamp';

export default {
  namespaced: true,
  modules: {
    api: apiService,
    // auth: authenticationService,
    benefit: benefitService,
    course: courseService,
    department: departmentService,
    exam: examService,
    language: languageService,
    router,
    translation: translationService,
    local: localService,
    server: serverService,
    recovery: recoveryService,
    achievement: achievementService,
    merger: mergerService,
    bootcamp: bootcampService,
  },
};
