export default {
  get: function(){
    return {
      fetching:null,
      error: null,
      values: [],
      view:{
        sending: false,
        data:{}
      }
    }
  }
}
