const fetching = state => state.api.get.fetching;

/**
 * Contains all calendars
 * @param state
 * @returns {*}
 */
const list = state => state.list;

/**
 * All of the following parameters are "current calendar" dependent
 * @param state
 * @returns {*}
 */
const id = state => state.current.id;
const idCourseCalendar = state => state.current.id_course_calendar;
const survey = state => state.current.survey;
const remote = state => state.current.remote;
const lastViewedClass = state => state.current.lastViewedClass;
const teacher = state => state.current.teacher;
const diplomas = state => state.current.diplomas;
const state =  state => state.current.state;

export default {
  fetching,
  list,
  id,
  idCourseCalendar,
  survey,
  remote,
  lastViewedClass,
  teacher,
  diplomas,
  state
}
