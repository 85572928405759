import api from '../api'
import uniqid from 'uniqid';

const fetch = ({commit, dispatch, rootGetters}, args) => {
  commit('FETCHING', true);
  let loaderId = uniqid()

  let translations = rootGetters['services/api/translation/getTranslations'];
  let message = translations.loaders.course;

  dispatch('services/api/api/addDescription',
    {
      id: loaderId,
      message,
    },
    { root: true },
  )

  return api.fetch(args)
    .then(response => {
      commit('FETCH_UPDATED', response);
      commit('FETCHING', false);

      return response.data;
    })
    .catch(error => {
      commit('FETCHING', false);

      return error.response;
    })
    .finally(() => {
      dispatch('services/api/api/removeDescription',
        {
          id: loaderId,
          delay: 500,
        },
        { root: true },
      )
    })
}

export default {
  fetch
}
