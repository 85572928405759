import initialState from './state.js'

const FETCH_UPDATED = (state, data) => {
  state.certificate = data.certificate;
  state.formats = data.formats;
  state.achievement = data.achievement;
};

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
};

const FETCHING = (state, value) => {
  state.fetching = !!value;
};

const SET_USER_CERTIFICATES = (state, data) => {
  state.certificates = data;
};

const CERTIFIED = (state, value) => {
  state.certified = value;
}

export default {
  FETCH_UPDATED,
  FETCHING,
  RESET_STATE,
  SET_USER_CERTIFICATES,
  CERTIFIED
};
