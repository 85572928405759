import contactService from './contact'
import listService from './list'

export default {
	namespaced:true,
	modules: {
		contact: contactService,
    list: listService
	}
}
