import listService from './list'
import userService from './user'

export default {
  namespaced:true,
  modules:{
    list: listService,
    user: userService
  }
}
