import initialState from './state.js'

const FETCH_UPDATED = (state, data) => {
  let _data = data.data;
  state.uri = _data.uri;
}

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
}

const FETCHING = (state, value) => {
  state.fetching = !!value;
}

export default {
  FETCH_UPDATED,
  FETCHING,
  RESET_STATE
};
