const width = state => state.width;
const height = state => state.height;
const mobile = state => state.mobile;
const focused = state => state.focused;

const titleIsFlashing = state => {
  return state.titleFlash.interval !== null;
}

export default {
  width,
  height,
  mobile,
  focused,
  titleIsFlashing
}
