import initialState from './state.js'

const RESET_STATE = state => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
}

const FETCHING = (state, value) => {
  state.fetching = !!value;
}

const ERROR = (state, error) => {
  state.error = error;
}

export default {
  FETCHING,
  ERROR,
  RESET_STATE,
};
