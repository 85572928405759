import api from '../api'

const mark = ({commit}, args) => {
  commit('RESET_STATE', true);

  return api.mark(args)
    .then(response => {
      commit('FETCHING', false);

      return response;
    })
    .catch(error => {
      commit('ERROR', error);
      commit('FETCHING', false);

      return error;
    })
}

export default {
  mark,
}
