import api from '../api'
import translationStore from '@/services/api/translation/store'

const send = ({ commit }, args, delay=1000) => {

  commit('ERROR_SENDING', true);

  return new Promise((resolve, reject) => {

    setTimeout( function() {

      return api.send(args)
        .then( (response) => {
          commit('ERROR_SENDING', false);
          commit('ERROR_SUCCESS', 200 === response.status);

          return resolve(response.data);
        })
        .catch((error) => {
          commit('ERROR_SENDING', false);
          console.error(error);

          return reject(error);
        });

    }, delay);

  });

}


export default {
  send,
}
