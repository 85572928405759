import xhrError from '@/services/api/api/store/error';

const initialState = () => ({
  pending: false,
  descriptions: [],
  error: xhrError.ERR_NONE,
});

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    pending(state) {
      return state.pending;
    },
    descriptions(state) {
      return state.descriptions;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    PENDING(state, value) {
      state.pending = value;
    },
    ADD_DESCRIPTION(state, value) {
      state.descriptions.push(value);
    },
    RESET_DESCRIPTIONS(state) {
      state.descriptions = [];
    },
    REMOVE_DESCRIPTION(state, args) {
      setTimeout(() => {
        state.descriptions = state.descriptions.filter((el) => el.id !== args.id);
      }, args.delay);
    },
    CONN_ERROR(state, args) {
      state.error = args;
    },
  },
  actions: {
    doRequest({ commit }) {
      commit('PENDING', true);
    },

    endRequest({ commit }) {
      commit('PENDING', false);
    },
    addDescription({ commit }, args) {
      commit('ADD_DESCRIPTION', args);
    },
    removeDescription({ commit }, args) {
      commit('REMOVE_DESCRIPTION', args);
    },

    notifyConnError({ commit }, args) {
      commit('CONN_ERROR', args);
    },
  },
};
