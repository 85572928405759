import api from '../api'
import fetchDelayed from '@/store/helper/fetchDelayed'

const fetch = (context, args, delay=500) => {
  let translations = context.rootGetters['services/api/translation/getTranslations'];
  let message = translations.loaders.course;

  return fetchDelayed.fetch(
    api,
    context,
    message,
    args,
    delay,
    false
  );
}

const leave = ({ commit }, args) => {
  commit('LEAVE_CALENDAR_SENDING', true);

  return api.abandonCalendar(args)
    .then(response => {
      commit('LEAVE_CALENDAR_SUCCESS', true)
      commit('LEAVE_CALENDAR_SENDING', false)

      return response;
    })
    .catch(error => {
      commit('LEAVE_CALENDAR_SUCCESS', false)
      commit('LEAVE_CALENDAR_SENDING', false)

      return error;
    });
}

const setCalendars= ({commit}, data) => {
  return new Promise((resolve, reject) => {
      commit('FETCH_UPDATED', data);
      return resolve(data);
  });
}

const reset = ({commit}, data) => {
  return new Promise((resolve, reject) => {
    commit('RESET_STATE');
    return resolve();
  });
}

const change = ({ commit }, calendarId) => {
  return new Promise((resolve, reject) => {
    commit('SET_CURRENT_CALENDAR', calendarId);
    return resolve(calendarId);
  })
}

export default {
  fetch,
  setCalendars,
  reset,
  change,
  leave
}
