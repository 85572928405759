import Api from '@/services/api/api/index.js'
import endPoints from './endpoint'

const fetch = (args) => {

  let params = new URLSearchParams();

  params.append('course', args.course)

  return Api.get(endPoints.get('get')+'?'+params.toString());
}

export default {
  fetch,
};
