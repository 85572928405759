import api from '../api'
import fetchDelayed from '@/store/helper/fetchDelayed'

const fetch = (context, args, delay=500) => {
  let translations = context.rootGetters['services/api/translation/getTranslations'];
  let message = translations.loaders.class_content;
  
  context.commit('RESET_STATE');

  return fetchDelayed.fetch(
    api,
    context,
    message,
    args,
    delay
  );
}

const setCurrent = ({ commit }, args) => {
  commit('SET_CURRENT_CLASS', args);
}

const markViewed = ({ commit }, args) => {
  commit('MARK_VIEWED_SENDING', true);

  return api.markViewed(args)
  .then((response) => {
    commit('MARK_VIEWED_SENDING', false);
    commit('MARK_VIEWED_SUCCESS', response.data);
    return response;
  })
  .catch((error) => {
    commit('MARK_VIEWED_SENDING', false);
    console.error(error);
    return error;
  });
}

export default {
  fetch,
  setCurrent,
  markViewed,
}
