import api from '../api'

const fetch = ({ commit }, args) => {
  commit('RESET_STATE');
  commit('FETCHING', true);

  return api.achievement(args)
    .then(response => {
      commit('FETCH_SUCCESS', true);
      commit('FETCHING', false);
      commit('SET_ACHIEVEMENTS', response.data);
      return response;
    })
    .catch(error => {
      commit('FETCH_SUCCESS', false);
      commit('FETCHING', false);
      commit('FETCH_ERROR', error);
      return error;
    });
};

export default {
  fetch
}
