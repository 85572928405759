import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();

  params.append('course', args.course);
  params.append('calendar', args.calendar);
  params.append('class', args.class);

  return Api.get(endpoints.get('get')+'?'+params.toString());
}

export default {
  fetch,
};
