import api from '../api'
import translationStore from '@/services/api/translation/store'
import fetchDelayed from '@/store/helper/fetchDelayed'
import _ from 'underscore';

const fetch = (context, args, delay=500, showLoader=true) => {
  let translations = context.rootGetters['services/api/translation/getTranslations'];
  let message = translations.loaders.course;

  //Reset posting wait time
  context.commit('POST_ADD_WAIT', 0);

  if(_.has(args, 'delay')){
    delay = parseInt(args.delay);
    delete(args.delay);
  }

  if(_.has(args,'showLoader')){
    showLoader = args.showLoader;
  }

  return fetchDelayed.fetch(
    api,
    context,
    message,
    args,
    delay,
    showLoader
  );
}

const add = ({ commit }, args, delay=500) => {
  commit('POST_SENDING', true);
  commit('POST_ADD_WAIT', 0);

  return new Promise((resolve, reject) => {

    setTimeout( function() {

      return api.add(args)
        .then( (response) => {
          commit('POST_SENDING', false);
          commit('POST_ADD', response.data);

          return resolve(response.data);
        })
        .catch((error) => {
          commit('POST_SENDING', false);

          if(429 === error.response.status){
            commit('POST_ADD_WAIT', error.response.data.wait);
          }

          return reject(error);
        });

      }, 
      delay
    );

  });

}

export default {
  fetch,
  add
}
