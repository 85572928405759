import Api from '@/services/api/api/index.js'
import endPoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('calendar_id', args.calendar_id);

  return Api.get(endPoints.get('fetch')+'?'+params.toString());
}

const add = (args) => {
	let options = {
		headers:{
    		'Content-Type': 'application/x-www-form-urlencoded'
    	}
  },
  params = new URLSearchParams();

	params.append('calendar_id', args.calendar_id);
  params.append('message', args.message);

	return Api.post(
		endPoints.get('add'),
		params,
		options
	);
}

export default {
  fetch,
  add
};
