import initialState from './state.js'

const FETCH_UPDATED = (state, data) => {
  let survey = data.data.survey,
  course = data.data.course;
  state.completed = data.data.completed;
  state.teacher = course.teacher;
  state.api.get.values = survey;
  state.course = course;
  state.sections = survey.sections;
  state.survey.id = survey.id;
  state.survey.name = survey.name;
  state.survey.credits = survey.credits;
}

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  state.completed = null;
  iState.api.get.fetching = state.api.get.fetching;
  Object.assign(state, initialState);
}

const SURVEY_ANSWERING = (state, value) => {
	state.api.post.answer.sending = !!value;
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value;
}


export default {
  FETCH_UPDATED,
  FETCHING,
  RESET_STATE,
  SURVEY_ANSWERING,
};
