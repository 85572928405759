import api from '../api'

const fetch = ({commit}, args) => {
  commit('SET_CLASSES', []);
  commit('FETCHING', true);
  commit('ERROR', null);

  return api.fetch(args)
    .then(r => {
      commit('SET_CLASSES', r.data)
      commit('FETCHING', false)
      return r;
    })
    .catch(e =>{
      commit('ERROR', e);
      commit('FETCHING', false)
      return e;
    })

}

const reset = ({commit}) =>{
  commit('RESET_STATE');
}

export default {
  fetch,
  reset
}
