import MyCache from './cache'
import StoreCache from '@/services/api/cache'

const SIDEBAR_COLLAPSE = (state, value) => {
  state.isCollapsed = !!value;
  StoreCache.save(MyCache.SIDEBAR_STORAGE_KEY, state);
}

export default {
  SIDEBAR_COLLAPSE
}
