import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.sending = state.sending;
  Object.assign(state, initialState);
}

const CONTACT_SUCCESS = (state, value) => {
	state.success = !!value;
}

const CONTACT_SENDING = (state, value) => {
  state.sending = !!value;
}

export default {
	CONTACT_SENDING,
	CONTACT_SUCCESS,
	RESET_STATE
}