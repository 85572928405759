import Api from '@/services/api/api/index.js'
import endPoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('calendar_id', args.calendar_id);

  return Api.get(endPoints.get('classMatesFetch')+'?'+params.toString());
}

export default {
  fetch,
};
