import achievementService from '@/services/api/achievement/achievement'
import achievementCareerService from '@/services/api/achievement/career'
import achievementCertificate from '@/services/api/achievement/certificate'


export default {
	namespaced:true,
	modules:{
    achievement: achievementService,
    career: achievementCareerService,
    certificate: achievementCertificate
  }
}
