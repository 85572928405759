import axios from 'axios';

const initialState = () => ({
  fetching: null,
  error: null,
  values: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    FETCH_UPDATED(state, data) {
      data.forEach(area => {
        state.values.push({
          code: area.area_id,
          name: area.nombre,
        })
      })
    },
    RESET_STATE(state) {
      Object.assign(state, initialState());
    },
    FETCHING(state, value){
      state.fetching = !!value;
    }
  },
  actions: {
    async fetch({ commit }) {
      commit('RESET_STATE');
      commit('FETCHING', true);

      return await axios
        .get(process.env.VUE_APP_EDUCACIONIT_API_SERVER + "public/areas?pais_id=AR", {})
        .then((response) => {
          commit('FETCH_UPDATED', response.data.data);
          commit('FETCHING', false);

          return response;
        })
        .catch((error) => {
          commit('FETCHING', false);
          return error;
        });
    },
  },
  getters: {
    fetching: (state) => state.fetching,
    areas: (state) => state.values,
  },
};
