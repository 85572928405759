import api from "../api"
import state from "./state"

const getList = ({ commit }, args, delay = 1000) => {
  commit("LIST_SENDING", true)
  commit("LIST_VALUES", state.get().list)

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return api
        .getList(args)
        .then(response => {
          commit("LIST_SENDING", false)
          commit("LIST_SUCCESS", 200 === response.status)
          commit("LIST_VALUES", response.data)

          return resolve(response.data)
        })
        .catch(error => {
          commit("LIST_SENDING", false)
          commit("LIST_SUCCESS", false)
          commit("LIST_ERROR", error)

          return reject(error)
        })
    }, delay)
  })
}

export default {
  getList
}
