export default {
  get: function(){
    return{
      width: 0,
      height:0,
      mobile: false,
      focused: true,
      originalTitle: null,
      titleFlash: {
        interval: null
      }
    }
  }
}
