import recordingStore from './recording';
import listStore from './list';

export default {
  namespaced: true,
  modules: {
    recording: recordingStore,
    list: listStore
  }
}
