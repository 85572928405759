import initialState from "./state.js"
import {$dayjs} from "@/plugins/dayjs"

const FETCH_UPDATED = (state, data) => {
  const calendarId = parseInt(data.args.calendar)

	state.list = data.data

	return SET_CURRENT_CALENDAR(state, calendarId)
}

const SET_CURRENT_CALENDAR = (state, calendarId = null) => {
	calendarId = parseInt(calendarId)
	const calendars = state.list
		.map(cal => ({
			...cal,
			current: calendarId && calendarId === cal.id_course_calendar
		}))
		.sort((a, b) => ($dayjs(a.dates.start).isBefore($dayjs(b.dates.start)) ? 1 : -1))

	const current = calendarId ? calendars.find(cal => cal.id_course_calendar === calendarId) : calendars[0]
	state.list = calendars
	state.current = { ...state.current, ...current }
}

const SET_LAST_VIEWED_CLASS = (state, classId) => {
	state.lastViewedClass.classId = classId
}

const RESET_STATE = state => {
	const iState = initialState.get()
	//Preserve fetching state
	iState.api.get.fetching = state.api.get.fetching
	Object.assign(state, iState)
}

const FETCHING = (state, value) => {
	state.api.get.fetching = !!value
}

const LEAVE_CALENDAR_SENDING = (state, value) => {
	state.leave.sending = !!value
}

const LEAVE_CALENDAR_SUCCESS = (state, value) => {
	state.leave.success = !!value
}

export default {
	FETCH_UPDATED,
	FETCHING,
	RESET_STATE,
	SET_CURRENT_CALENDAR,
	SET_LAST_VIEWED_CLASS,
	LEAVE_CALENDAR_SENDING,
	LEAVE_CALENDAR_SUCCESS
}
