import api from "@/services/api/api/index.js"

const initialState = () => {
  return {
    fetching: false,
    error: false,
    success: false,
    achievements: []
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    achievements(state) {
      return state.achievements
    },
    success(state) {
      return state.success
    },
    fetching(state) {
      return state.fetching
    },
    error(state) {
      return state.error
    }
  },
  mutations: {
    RESET_STATE(state) {
      const iState = initialState()
      //Preserve fetching state
      iState.fetching = state.fetching
      Object.assign(state, iState)
    },
    FETCHING(state, value) {
      state.fetching = !!value
    },
    FETCH_SUCCESS(state, value) {
      state.success = !!value
    },
    FETCH_ERROR(state, value) {
      state.error = value
    },
    SET_ACHIEVEMENTS(state, values) {
      state.achievements = values
    }
  },
  actions: {
    fetch({ commit }) {
      commit("RESET_STATE")
      commit("FETCHING", true)

      return api
        .get("/achievement/user")
        .then(response => {
          commit("FETCH_SUCCESS", true)
          commit("FETCHING", false)
          commit("SET_ACHIEVEMENTS", response.data)
          return response
        })
        .catch(error => {
          commit("FETCH_SUCCESS", false)
          commit("FETCHING", false)
          commit("FETCH_ERROR", error)
          return error
        })
    },
    seen(_, version) {
      const params = new URLSearchParams()
      params.append("version", version)
      return api
        .get("/achievement/user/seen?" + params.toString())
        .then(response => {
          return response
        })
        .catch(error => {
          return error
        })
    }
  }
}
