import uniqid from 'uniqid';

const fetch = (api, context, message, args, delay = 500, showLoader = true) => {
  const { commit } = context;
  const { dispatch } = context;
  // const rootGetters = context.rootGetters
  const loaderId = uniqid();
  delay = parseInt(delay);

  commit('FETCHING', true);
  commit('RESET_STATE');

  if (showLoader === true) {
    // Add XHR Loader description
    dispatch(
      'services/api/api/addDescription',
      {
        id: loaderId, // Random uniqid (could also be server side generated)
        message, // Translation value
      },
      { root: true },
    );
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => api
      .fetch(args)
      .then((response) => {
        commit('FETCHING', false);
        commit('FETCH_UPDATED', { args, data: response.data });

        if (showLoader === true) {
          // Once the request is done, remove the description by id
          dispatch(
            'services/api/api/removeDescription',
            {
              id: loaderId,
              delay: 1000,
            },
            { root: true },
          );
        }

        if (response.status !== 200) {
          return reject(response);
        }

        return resolve(response.data);
      })
      .catch((error) => {
        commit('FETCHING', false);

        if (showLoader === true) {
          dispatch(
            'services/api/api/removeDescription',
            {
              id: loaderId,
              delay: 1000,
            },
            { root: true },
          );
        }

        return reject(error);
      }), delay);
  });
};

export default {
  fetch,
};
