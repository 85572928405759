const details = state => state.details;
const success = state => state.success;
const fetching = state => state.fetching;
const error = state => state.error;

export default {
  details,
  success,
  fetching,
  error
}
