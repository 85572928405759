export default {
  get: function(){
    return {
      courses: null,
      fetching: false,
      error: false,
      success: false
    }
  }
}
