import api from '@/services/api/api';

const initialState = () => ({});

const formHeaders = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {},
  actions: {
    async rate(_, payload) {
      return api.post('/course/calendar/class/summary/rate', payload, formHeaders);
    }
  },
};
