import api from '../api'
import fetchDelayed from '@/store/helper/fetchDelayed'

const fetch = (context, args, delay=500) => {
  let message = 'Obteniendo video';

  return fetchDelayed.fetch(
    api,
    context,
    message,
    args,
    delay
  );
}

export default {
  fetch
}
