import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.sending = state.sending;
  Object.assign(state, initialState);
}

const LIST_SENDING = (state, value) => {
  state.sending = !!value;
}


const LIST_SUCCESS = (state, value) => {
	state.success = !!value;
}

const LIST_ERROR = (state, value) => {
  state.error = value;
}

const LIST_VALUES = (state, value) => {
  state.list = value;
}

export default {
	LIST_SENDING,
  LIST_SUCCESS,
  LIST_ERROR,
  LIST_VALUES,
  RESET_STATE
}
