import dashboardLocalStoreService from '@/services/api/local/dashboard'
import sidebarLocalStoreService from '@/services/api/local/sidebar'
import appSettingsStoreService from '@/services/api/local/app'

export default {
	namespaced:true,
	modules:{
	  dashboard: dashboardLocalStoreService,
    sidebar: sidebarLocalStoreService,
    app: appSettingsStoreService
  }
}
