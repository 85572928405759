import initialState from "./state.js"

const FETCH_UPDATED = (state, data) => {
  state.api.get.values = data.data
  state.viewed = data.data.viewed
}

const RESET_STATE = state => {
  const iState = initialState.get()
  //Preserve fetching state
  iState.api.get.fetching = state.api.get.fetching
  Object.assign(state, initialState)
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value
}

const MARK_READ_SENDING = (state, value) => {
  state.read.sending = !!value
}

const SET_RESOURCE_VIEWED = (state, value) => {
  state.viewed = !!value
}

const RATE_SUCCESS = (state, data) => {
  state.rate.data = data
}

const RATE_ERROR = (state, value) => {
  state.rate.error = !!value
}

const RATE_SENDING = (state, value) => {
  state.rate.sending = !!value
}

export default {
  FETCH_UPDATED,
  FETCHING,
  RESET_STATE,
  MARK_READ_SENDING,
  SET_RESOURCE_VIEWED,
  RATE_SENDING,
  RATE_SUCCESS,
  RATE_ERROR
}
