export default {
  get: function () {
    return {
      fetching: null,
      error: null,
      date: null,
      course: {},
      list: [],
      currentRecording: null,
      currentTeacher: null,
      currentParts: [],
      currentPart: null
    }
  }
}
