import api from '../api'

const fetch = ({commit}, args) => {
  commit('RESET_STATE', true);

  return api.fetch(args)
    .then(response => {
      commit('SET_DATA', response.data);
      commit('FETCHING', false);

      return response.data;
    })
    .catch(error => {
      commit('ERROR', error);
      commit('FETCHING', false);

      return error;
    })
}

export default {
  fetch
}
