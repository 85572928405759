import classesStore from "./classes"
import contentStore from "./content/content"
import resourceStore from "./content/resource"

export default {
  namespaced: true,
  modules: {
    classes: classesStore,
    content: contentStore,
    resource: resourceStore
  }
}
