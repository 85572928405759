export default {
  get: function(){
    return{
      result: {
        score:0
      },
      course:{},
      exam:{},
      questions:[],
      comment:{},
      api:{
        get:{
          fetching: false,
          error: false,
          values: [],
        },
        put: {

        },
        post:{
          rate:{
            sending: false,
            data: {}
          }
        },
        delete:{
        }
      }
    }
  }
}
