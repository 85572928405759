export default {
  get: function(){
    return {
      list:[], //All calendars
      current: {
        id: null,
        dates:{},
        teacher:{},
        survey:{},
        lastViewedClass: {},
        state: {}, //Course calendar state (abandoned, expired, etc)
        remote: null, //Determines if course calendar is "remote" (taken by distance) or face to face (local)
        diplomas:[],
      },
      leave:{
        sending: null,
        success: null
      },
      api:{
        get:{
          fetching: null,
          error: null,
          values: {},
        },
        put: {

        },
        post:{

        },
        delete:{

        }
      }
    }
  }
}
