/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();

ENDPOINT.set('list', '/credits/course/list');

export default ENDPOINT
