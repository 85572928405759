export default {
  get: function(){
    return {
      questions: [],
      examConfig:{
        timeLeft: null
      },
      api:{
        get:{
          fetching: null,
          error: false,
          values: [],
        },
        put: {

        },
        post:{
          answering: false,
          viewing: false,
          reviewing: false
        },
        delete:{

        }
      }
    }
  }
}
