export default {
  get: function(){
    return {
      branch:{
        name: '',
        location:''
      },
      course:{
        id: 0,
        name:'',
        date:'',
        calendar:'',
        branch: '',
      },
      png: '',
      pdf: '',
      html: '',
      png64: '',
      api:{
        get:{
          fetching: false,
          error: false,
          values: {},
        },
        put: {

        },
        post:{

        },
        delete:{

        }
      }
    }
  }
}
