import Api from "@/services/api/api/"
import api from "../api"
// import translationStore from "@/services/api/translation/store"
import fetchDelayed from "@/store/helper/fetchDelayed"

const fetch = (context, args, delay = 500) => {
	const translations = context.rootGetters["services/api/translation/getTranslations"]
	const message = translations.loaders.course_classes

	return fetchDelayed.fetch(api, context, message, args, delay)
}

const markAsReaded = async ({ commit }, args) => {
	commit("MARK_READ_SENDING", true)

	const options = {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		}
	}
	const params = new URLSearchParams()

	params.append("class", args.class.id)
	params.append("resource", args.resource.id)
	params.append("value", args.value ? 1 : 0)
	//console.log({ args })
	try {
		const response = await Api.post("/class/resource/view", params, options)
		commit("MARK_READ_SENDING", false)

		if (200 !== response.status) {
			throw ["Could not set resource as readed ", args].join("")
		}
		commit("SET_RESOURCE_VIEWED", args.value)
	} catch (err) {
		commit("MARK_READ_SENDING", false)
	}
}

const rate = ({ commit }, args, delay = 1000) => {
	commit("RATE_SENDING", true)
	commit("RATE_ERROR", false)
	commit("RATE_SUCCESS", false)

	return new Promise((resolve, reject) => {
		setTimeout(() => {
			return api
				.rate(args)
				.then(response => {
					commit("RATE_ERROR", false)
					commit("RATE_SENDING", false)
					commit("RATE_SUCCESS", response.data)

					return resolve(response.data)
				})
				.catch(error => {
					commit("RATE_SENDING", false)
					commit("RATE_ERROR", true)
					commit("RATE_SUCCESS", false)

					return reject(error)
				})
		}, delay)
	})
}

export default {
	fetch,
	markAsReaded,
	rate
}
