<template>
  <v-container v-if="show" class="pa-0">
    <v-snackbar :value="show" :timeout="timeout" :multi-line="multiLine">
      <v-layout row justify-center align-center>
        <v-flex xs2>
          <img class="logo pr-2" width="60" src="https://static.educacionit.com/alumni/assets/generic/logo.svg" />
        </v-flex>

        <v-flex xs10>
          <p class="animated fadeInUp" v-for="(description, i) in descriptions" :key="`snackbar-${i}`">
            {{ description.message }} ...
          </p>
        </v-flex>
      </v-layout>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "TheSnackbarLoader",
  data() {
    return {
      bottom: true,
      absolute: true,
      multiLine: true
    }
  },
  computed: {
    ...mapGetters({
      pending: "services/api/api/pending",
      descriptions: "services/api/api/descriptions"
    }),
    timeout() {
      return this.descriptions.length * 2000 //Add 500ms delay for each description7
    },
    show() {
      return this.descriptions.length > 0
    }
  }
}
</script>

<style scoped>
.logo {
  width: 40px;
  margin-right: 10px;
}
</style>
