const success = state => state.success;
const sending = state => state.sending;
const error = state => state.error;
const list = state => state.list;

export default {
  sending,
  success,
  error,
  list
};
