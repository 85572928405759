import api from '../api'

const fetch = ({commit}, args) => {

  commit('RESET_STATE');
  commit('FETCHING', true);

  return api.fetch(args)
    .then(response => {
      commit('FETCH_UPDATED', response.data);
      commit('FETCHING', false);
      return response;
    })
    .catch(error => {
      commit('FETCHING', false);
      commit('ERROR', error);
      return error;
    })

};
export default {
  fetch,
}
