import actions from "./store/actions"
import getters from "./store/getters"
import mutations from "./store/mutations"
import initialState from "./store/state"

export default {
  namespaced: true,
  state: initialState.get(),
  actions,
  getters,
  mutations
}
