<template>
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card>
      <v-card-title class="bg-it-primary tw-text-white title"> Te pedimos disculpas </v-card-title>
      <v-card-text>
        Por el momento la plataforma Alumni no se encuentra disponible. Nuestro equipo está trabajando para volver a la
        normalidad lo antes posible.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import xhrError from "@/services/api/api/store/error"
export default {
  name: "TheNoConnectionModal",
  computed: {
    ...mapGetters({
      error: "services/api/api/error"
    }),
    show() {
      return this.error === xhrError.NO_CONNECTION
    }
  },
  methods: {
    retry() {
      this.$router.go(this.$router.history.current).catch({})
    }
  }
}
</script>
