import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('course_code', args.course_code);
  return Api.get(endpoints.get('get')+'?'+params.toString());
}

const start = (args) => {
  let params = new URLSearchParams();

  params.append('course_code', args.course_code);
  params.append('exam', args.exam);

  return Api.post(endpoints.get('start'), params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

const finish = (args) => {
  let params = new URLSearchParams();

  params.append('course_code', args.course_code);
  params.append('exam', args.exam);
  params.append('finish', args.calendar);

  return Api.post(endpoints.get('finish'), params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

const result = (args) => {
  let params = new URLSearchParams();
  params.course_code = args.course_code;
  params.exam = args.exam;

  return Api.post(endpoints.get('finish'), params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}


export default {
  fetch,
  start,
  finish,
  result,
};
