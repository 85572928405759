import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
}

const SET_DATA = (state, data) => {
  state.uri = data.uri;
}

const FETCHING = (state, value) => {
  state.fetching = !!value;
}

const ERROR = (state, error) => {
  state.error = error;
}

export default {
  RESET_STATE,
  SET_DATA,
  FETCHING,
  ERROR
};
