import api from '../api'

const send = ({ commit }, args, delay=1000) => {

  commit('CONTACT_SENDING', true);

  return new Promise((resolve, reject) => {

    setTimeout( function() {

      return api.contact(args)
        .then( response => {
          commit('CONTACT_SENDING', false);
          commit('CONTACT_SUCCESS', 200 === response.status);

          return resolve(response.data);
        })
        .catch(error => {
          commit('CONTACT_SENDING', false);
          commit('CONTACT_SUCCESS', false);
          console.error(error);

          return reject(error);
        });

    }, delay);

  });

}

export default {
  send,
}
