export default {
  get: function () {
    return {
      supported: null,
      enable: true,
      granted: null,
      notifications: []
    }
  }
}
