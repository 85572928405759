const courses = state => state.courses;
const success = state => state.success;
const fetching = state => state.fetching;
const error = state => state.error;

export default {
  courses,
  success,
  fetching,
  error
}
