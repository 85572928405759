import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import router from '@/router';

const fireBaseApp = initializeApp({
  apiKey: process.env.VUE_APP_GI_API_KEY,
  authDomain: process.env.VUE_APP_GI_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_GI_PROJECT_ID,
  storageBucket: process.env.VUE_APP_GI_STORAGE_BUCKET,
  appId: process.env.VUE_APP_GI_APP_ID,
})

export default {
  namespaced: true,
  state: {
    auth: getAuth(fireBaseApp),
    host: process.env.VUE_APP_HOST,
    clientId: process.env.VUE_APP_CLIENT_ID,
    authHost: process.env.VUE_APP_EDUCACIONIT_AUTH_HOST
  },
  actions: {
    authRedirect({commit, state}, data){
      const statePayload = {query: data.route.query}
      const argsBase64 = encodeURIComponent(window.btoa(JSON.stringify(statePayload)));

      // eslint-disable-next-line vue/max-len
      window.location.href = `${state.authHost}/${data.action}?redirect_uri=${state.host}/auth-return&client_id=${state.clientId}&state=${argsBase64}`;
    },
    async initializeFirebase({ _, state }) {
      onAuthStateChanged(state.auth, async (user) => {
        if (user) {
          user.accessToken = await user.getIdToken(true)
        }
      });
    },
    async checkAuthRoute({dispatch}, data){
      if (data.user || router.history.current.name === 'Auth') return
      dispatch('authRedirect', {action: 'authorize', route: data.route})
    },
    async logOut({ state }) {
      window.location.href = `${state.authHost}/logout?return_to=${state.host}/logout&client_id=${state.clientId}`;
    },
    async signOut({ state }) {
      if (!state.auth) return;
      try {
        await signOut(state.auth);
      } catch (error) {
        console.log({ error });
      }
    },
    async keepAlive({ state }, { interval }) {
      setInterval(async () => {
        state.auth.currentUser.accessToken = await state.auth.currentUser.getIdToken(true)
      }, interval * 1000 * 60);
    }
  }
}
