import settings from '@/settings'

const SET_WINDOW_WIDTH = (state, value) => {
  state.width = value;
  state.mobile = value < settings.mobile.breakpoint;
}

const SET_WINDOW_HEIGHT = (state, value) => {
  state.height = value;
}

const SET_FOCUSED = (state, value) => {
  state.focused = !!value;
}

const TITLE_FLASH_START = (state, args) => {
  let originalTitle = document.title,
    flag = false,
    interval = typeof args['interval'] === 'undefined' ? 2000 : parseInt(args.interval),
    stopOnFocus = typeof args['stopOnFocus'] !== 'undefined';

  state.originalTitle = originalTitle;

  state.titleFlash.interval = setInterval(function(){
    flag = !flag;
    document.title = flag ? args.message : originalTitle;
  }, interval)

  if(false === stopOnFocus){
    return;
  }

  window.addEventListener('focus', function() {
    TITLE_FLASH_STOP(state);
  },{
    once: true
  });

}

const TITLE_FLASH_STOP = (state) => {
  clearInterval(state.titleFlash.interval);
  state.titleFlash.interval = null;
  document.title = state.originalTitle;
}

export default {
  SET_WINDOW_HEIGHT,
  SET_WINDOW_WIDTH,
  SET_FOCUSED,
  TITLE_FLASH_START,
  TITLE_FLASH_STOP
}
