/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();
ENDPOINT.set('get', '/exam/ongoing/questions/get');
ENDPOINT.set('submit', '/exam/answer/submit');
ENDPOINT.set('view', '/exam/ongoing/question/view');
ENDPOINT.set('review', '/exam/ongoing/question/review');

export default ENDPOINT