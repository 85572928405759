import api from '@/services/api/api';

const initialState = () => ({
  course: null,
  courses: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setCourse(state, payload) {
      state.course = payload;
    },
    setCourses(state, payload) {
      state.courses = payload;
    },
  },
  actions: {
    async fetch({ commit }) {
      const { data } = await api.get('/course/get/unified');
      commit('setCourses', data);
      return data;
    },
  },
};
