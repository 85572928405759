import MyCache from "./cache"
import StoreCache from "@/services/api/cache/localStorage"

const DARK_MODE = state => {
  state.darkMode = false
  StoreCache.save(MyCache.KEY, { darkMode: false })
}

export default {
  DARK_MODE
}
