import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const requestMerge = (args) => {
  let params = new URLSearchParams();
  params.append('to', args.to);
  return Api.get(endpoints.get('requestMerge')+'?'+params.toString());
}

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('hash', args.hash);
  return Api.get(endpoints.get('fetch')+'?'+params.toString());
}

const merge = (args) => {
  let params = new URLSearchParams();
  params.append('hash', args.hash);
  return Api.get(endpoints.get('merge')+'?'+params.toString());
}

export default {
  requestMerge,
  fetch,
  merge
};
