const requestMergeSending = state => state.request.sending;
const requestMergeSuccess = state => state.request.success;
const requestMergeErrors = state => state.request.errors;
const mergeSending = state => state.merge.sending;
const mergeSuccess = state => state.merge.success;
const mergeError = state => state.merge.error;
const users = state => state.users;

export default {
  requestMergeSending,
  requestMergeSuccess,
  requestMergeErrors,
  mergeSending,
  mergeSuccess,
  mergeError,
  users
}
