const fetching = state => state.fetching;
const certificate = state => state.certificate;
const certificates = state => state.certificates;
const isCertified = state => state.certified;
const achievement = state => state.achievement;
const formats = state => state.formats;

const html = state => {
  return state.formats.find(item => {
    return item.format === 'html';
  })
};

const pdf = state => {
  return state.formats.find(item => {
    return item.format === 'pdf';
  })
};

const png = state => {
  return state.formats.find(item => {
    return item.format === 'png';
  })
};

const png64 = state => {
  return state.formats.find(item => {
    return item.format === 'png64';
  })
};

export default {
  fetching,
  certificate,
  certificates,
  isCertified,
  achievement,
  formats,
  html,
  pdf,
  png,
  png64,
}
