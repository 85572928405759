
export default {
  isFetching(state){
    return state.api.get.fetching;
  },
  getData(state){
    return state.api.get.values;
  },
  isBeingRated(state){
  	return state.rate.sending;
  },
  viewed(state){
  	return state.viewed;
  },
  rateError(state){
    return state.rate.error;
  }
}
