import questionStore from './question'
import examStore from './exam'
import attemptStore from './attempt'
import errorStore from './error'

export default {
	namespaced: true,
	modules:{
		exam: examStore,
		question: questionStore,
		attempt: attemptStore,
		error: errorStore,
	}
};
