import initialState from './state.js'

const SET_DATA = (state, data) => {
  state.course = data.calendar.course;
  state.date = data.calendar.date;
  state.list = data.resources.recordings;
}

const RESET_STATE = state => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
}

const FETCHING = (state, value) => {
  state.fetching = !!value;
}
const ERROR = (state, error) => {
  state.error = error;
}

const SET_CURRENT_RECORDING = (state, number) => {
  for(let i = 0; i < state.list.length; i++) {
    let item = state.list[i];

    if(number !== item.class) {
      continue;
    }

    state.currentTeacher = item.teacher;
    state.currentRecording = item;
    state.currentParts = item.parts;

    return SET_CURRENT_RECORDING_PART(state, 1);
  }

  throw "Could not set current recording to class number:" + number;
}

const SET_CURRENT_RECORDING_PART = (state, number) => {
  number = parseInt(number);

  for(let i = 0; i < state.currentParts.length; i++){
    let part = state.currentParts[i];
    if(number === part.number){
      state.currentPart = part;
      return;
    }
  }
}
const NEXT_PART = state => {

}

const PREV_PART = state => {

}

const NEXT_RECORDING = state => {

}

const PREV_RECORDING = state => {

}

export default {
  FETCHING,
  SET_DATA,
  ERROR,
  RESET_STATE,
  SET_CURRENT_RECORDING,
  SET_CURRENT_RECORDING_PART,
  PREV_RECORDING,
  NEXT_RECORDING,
  PREV_PART,
  NEXT_PART
};
