export default {
  get: function(){
    return {
      users:[],
      request:{
        sending: null,
        success: null,
        errors: false
      },
      merge:{
        sending: null,
        success: null,
        error: false
      }
    }
  }
}
