import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('course_code', args.course_code);
  params.append('exam', args.exam);

  return Api.get(endpoints.get('get')+'?'+params.toString());
}

const submit = (args) => {
  let params = new URLSearchParams();

  params.append('course_code', args.course_code);
  params.append('exam', args.exam);
  params.append('question', args.question);

  for(let i = 0; i < args.answer.length; i++){
    params.append('answer[]', args.answer[i]);
  }

  return Api.post(endpoints.get('submit'), params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

const view = (args) => {
  let params = new URLSearchParams();

  params.append('course_code', args.course_code);
  params.append('exam', args.exam);
  params.append('question', args.question);

  return Api.post(endpoints.get('view'), params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

const review = (args) => {
  let params = new URLSearchParams();

  params.append('course_code', args.course_code);
  params.append('exam', args.exam);
  params.append('question', args.question);
  params.append('value', !!args.value ? 1 : 0);

  return Api.post(endpoints.get('review'), params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export default {
  fetch,
  submit,
  view,
  review,
};
