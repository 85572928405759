
export default {
  isFetching(state){
    return state.api.get.fetching;
  },
  get(state){
    return state.api.get.values;
  },
  getQuestions(state){
  	return state.questions;
  },
  getConfig(state){
  	return state.examConfig;
  },
  answering(state){
    return state.api.post.answering
  }

}
