const fetching = state => state.fetching;
const date = state => state.date;
const course = state => state.course;
const list = state => state.recordings;
const currentRecording = state => state.currentRecording;
const currentParts = state => state.currentParts;
const currentPart = state => state.currentPart;
const currentTeacher = state => state.currentTeacher;

export default {
  fetching,
  course,
  date,
  list,
  currentRecording,
  currentTeacher,
  currentParts,
  currentPart
}
