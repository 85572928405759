import MyCache from './cache'
import StoreCache from '@/services/api/cache'

const CONTENT_MESSAGE_STATUS = (state, value) => {
  state.contentMessageStatus = !!value;
  StoreCache.save(MyCache.MESSAGE_STORAGE_KEY, state);
}

export default {
  CONTENT_MESSAGE_STATUS
}
