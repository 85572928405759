import axios from 'axios';
import xhrError from '@/services/api/api/store/error';
import settings from '@/settings';
import cache from '@/services/api/cache';
import store from '@/store';
import router from '@/router';

const instance = axios.create({
  baseURL: settings.api.alumni.server,
  withCredentials: true,
  headers: settings.api.alumni.headers,
});

const ON_REQUEST_SUCCESS = (xhr) => {
  store.dispatch('services/api/api/endRequest');
  return xhr;
};

const ON_REQUEST_ERROR = (error) => {
  if (!error.response || error.code === 'ERR_NETWORK') {
    store.dispatch('services/api/api/notifyConnError', xhrError.NO_CONNECTION);
  }

  /**
   * If the response returns an invalid token header, logout
   * The web socket will be disconnected by the watcher set in App.vue
   */
  if (typeof error.response.headers['x-alumni-token-invalid'] !== 'undefined') {
    /**
     * Drops auth tokens and sets authenticated state to false
     */
    store.commit('Auth/logout');
  }

  /* If the API is in maintenance mode */
  if (error.response.status === 503) {
    return router.push({
      name: 'maintenance',
      params: {
        datetime: error.response.data.time,
      },
    });
  }

  return Promise.reject(error);
};

instance.interceptors.request.use(async (xhr) => {
  const authUser = store.state.AuthFirebase.auth.currentUser;

  if (authUser) {
    xhr.headers['Authorization'] = `Bearer ${await authUser.getIdToken()}`;
  }else if(cache.getItem('application/token')){
    xhr.headers['X-Alumni-Token'] = cache.getItem('application/token');
  }

  store.dispatch('services/api/api/doRequest');
  return xhr;
});

instance.interceptors.response.use(ON_REQUEST_SUCCESS, ON_REQUEST_ERROR);

export default instance;
