import api from '../api'

const details = ({ commit }) => {
  commit('RESET_STATE');
  commit('FETCHING', true);

  return api.details()
    .then(response => {
      commit('FETCH_SUCCESS', true);
      commit('FETCHING', false);
      commit('SET_DETAILS', response.data);
      return response;
    })
    .catch(error => {
      commit('FETCH_SUCCESS', false);
      commit('FETCHING', false);
      commit('FETCH_ERROR', error);
      return error;
    });
};

export default {
  details,
}
