const bootcamp = state => state.bootcamp;
const success = state => state.success;
const fetching = state => state.fetching;
const error = state => state.error;

export default {
  bootcamp,
  success,
  fetching,
  error,
}
