import api from '../api'
import fetchDelayed from '@/store/helper/fetchDelayed'
import _ from 'underscore';
import Store from '@/store'

const fetch = (context, args, delay=500, showLoader=true) => {
  let translations = context.rootGetters['services/api/translation/getTranslations'];
  let message = translations.loaders.survey;

  if(_.has(args, 'delay')){
    delay = parseInt(args.delay);
    delete(args.delay);
  }

  if(_.has(args,'showLoader')){
    showLoader = args.showLoader;
  }

  context.commit('RESET_STATE');

  return fetchDelayed.fetch(
    api,
    context,
    message,
    args,
    delay,
    showLoader
  );
}

const answer = ({ commit }, args, delay=500) => {
  commit('SURVEY_ANSWERING', true);

  if(_.has(args, 'delay')){
    delay = parseInt(args.delay);
    delete(args.delay);
  }

  return new Promise((resolve, reject) => {
    setTimeout( function() {

      return api.answer(args)
        .then( (response) => {
          commit('SURVEY_ANSWERING', false);

          Store.state.Auth.credits += response.data.credits
          return resolve(response.data);
        })
        .catch((error) => {
          commit('SURVEY_ANSWERING', false);

          return reject(error);
        });

      },
      delay
    );

  });

}

export default {
  fetch,
  answer
}
