import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// eslint-disable-next-line import/no-named-default
import { default as spanish } from 'dayjs/locale/es';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('America/Argentina/Buenos_Aires');
dayjs.locale('es', spanish);

export const $dayjs = dayjs

export default {
  install: Vue => {
    Object.defineProperty(Vue.prototype, "$dayjs", { value: dayjs })
  }
}
