import initialState from './state.js'

const FETCH_UPDATED = (state, data) => {
  state.api.get.values = data.data;
};

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.api.get.fetching = state.api.get.fetching;
  Object.assign(state, initialState);
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value;
}

export default {
  FETCH_UPDATED,
  FETCHING,
  RESET_STATE,
}
