import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve sending state
  iState.sending = state.sending;
  Object.assign(state, iState);
}

const ERROR_SENDING = (state, value) => {
  state.sending = !!value;
}

const ERROR_SUCCESS = (state, value) => {
  state.success = !!value;
}

export default {
  ERROR_SENDING,
  ERROR_SUCCESS,
  RESET_STATE
};
