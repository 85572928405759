/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();
ENDPOINT.set('get', '/achievement/user/certificate');
ENDPOINT.set('certificates', '/achievement/certificate/user/list');

export default ENDPOINT
