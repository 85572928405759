const get = state => state.history;
const last = state => {
  if(0 === state.history.length){
    return;
  }

  return state.history[state.history.length - 1];
}
export default {
	get,
  last
};
