export default {
  get: function(){
    return {
      sending: false,
      success: null,
      error: null,
      list: []
    }
  }
}
