import EventBus from "@/event"
import store from "@/store"

const init = ({ commit }, args) => {
  commit("SET_WINDOW_HEIGHT", document.documentElement.clientHeight)
  commit("SET_WINDOW_WIDTH", document.documentElement.clientWidth)

  setInterval(() => {
    commit("SET_FOCUSED", document.hasFocus())
  }, 300)

  window.addEventListener("resize", () => {
    commit("SET_WINDOW_HEIGHT", document.documentElement.clientHeight)
    commit("SET_WINDOW_WIDTH", document.documentElement.clientWidth)
  })
}

const titleFlashStart = ({ commit, getters, dispatch }, args) => {
  if (getters["titleIsFlashing"]) {
    return
  }

  commit("TITLE_FLASH_START", args)
}

const titleFlashStop = ({ commit, state }) => {
  if (null === state.titleFlash.interval) {
    return
  }

  commit("TITLE_FLASH_STOP")
}

const setWidth = ({ commit }, value) => {
  commit("SET_WINDOW_WIDTH", value)
}

const setHeight = ({ commit }, value) => {
  commit("SET_WINDOW_HEIGHT", value)
}

const setFocused = ({ commit }, value) => {
  commit("SET_WINDOW_FOCUSED", value)
}

const reload = () => {
  window.location.reload(true)
}

/**
 * !!!!!!!!!! Socket events !!!!!!!!!!!!!!!!!!
 */

EventBus.$on("alumni.client.reload", data => {
  setTimeout(() => {
    store.dispatch("services/browser/window/reload")
  }, data.response.interval)
})

export default {
  init,
  setWidth,
  setHeight,
  setFocused,
  titleFlashStart,
  titleFlashStop,
  reload
}
