import api from '../api'

const fetch = ({commit}, args) => {
  commit('RESET_STATE', true);

  return api.fetch(args)
    .then(response => {
      commit('SET_DATA', response.data);
      commit('FETCHING', false);

      return response;
    })
    .catch(error => {
      commit('ERROR', error);
      commit('FETCHING', false);

      return error;
    })
}

const setCurrentRecording = ({commit}, classNumber) => {
  commit('SET_CURRENT_RECORDING', classNumber);
}

const setCurrentRecordingPart = ({commit}, partNumber) => {
  commit('SET_CURRENT_RECORDING_PART', partNumber);
}

const nextRecording = ({commit}) => {
  commit('NEXT_RECORDING');
}

const prevRecording = ({commit}) => {
  commit('PREV_RECORDING');
}

const prevPart = ({commit}) =>{
  commit('PREV_PART');
}

const nextPart = ({commit}) =>{
  commit('NEXT_PART');
}

const reset = ({commit}, value) => {
  commit('RESET_STATE');
}

export default {
  fetch,
  setCurrentRecording,
  setCurrentRecordingPart,
  prevRecording,
  nextRecording,
  prevPart,
  nextPart,
  reset
}
