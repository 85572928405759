export default {
  get: function(){
    return {
      paginator:{},
      api:{
        get:{
          fetching: false,
          error: false,
          values: [],
        },
        put: {

        },
        post:{

        },
        delete:{

        }
      }
    }
  }
}
