import calendarStore from './calendar'
import postsStore from './posts'
import classMatesStore from './classMates'
import diplomaStore from './diploma'
import surveyStore from './survey'
import classStore from './class';

export default {
  namespaced:true,
  modules:{
    calendar: calendarStore,
    class: classStore,
    posts: postsStore,
    classMates: classMatesStore,
    diploma: diplomaStore,
    survey: surveyStore
  }
}
