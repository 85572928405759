import Api from '@/services/api/api/index.js'
import endPoints from './endpoint'

const fetch = (course) => {
  let params= new URLSearchParams();

  params.append('course', course.id);

  return Api.get(endPoints.get('get')+'?'+params.toString());
}

export default {
  fetch,
};
