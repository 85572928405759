import recordingListStore  from './list';
import recordingStore from './consumer';
import attendanceStore from './attendance';

export default {
  namespaced: true,
  modules: {
    list: recordingListStore,
    consumer: recordingStore,
    attendance: attendanceStore
  }
}
