import Api from "@/services/api/api"
import endpoints from "./endpoint.js"

const fetchLanguages = () => {
  return Api.get(endpoints.get("getLanguages"))
}

export default {
  fetchLanguages
}
