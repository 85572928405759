import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
};

const FETCHING = (state, value) => {
  state.get.fetching = !!value;
};

const FETCH_SUCCESS = (state, value) => {
  state.get.success = !!value;
};

const FETCH_ERROR = (state, value) => {
  state.get.error = value;
};

const SET_GITHUB_BENEFIT = (state, values) => {
  state.get.github = values;
};

export default {
  FETCHING,
  FETCH_SUCCESS,
  FETCH_ERROR,
  RESET_STATE,
  SET_GITHUB_BENEFIT
};
