import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

/**
 * Send an error report on an exam attempt
 */
const send = (args) => {
	let options = {
		headers:{
    		'Content-Type': 'application/x-www-form-urlencoded'
    	}
    },
	params = new URLSearchParams();

	params.append('attempt', args.attempt);
	params.append('question', args.question);
	params.append('text', args.text);

	return Api.post(
		endpoints.get('send'),
		params,
		options
	);
}

export default {
	send
};
