import api from '@/services/api/api';

const initialState = () => ({});

const formHeaders = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {},
  actions: {
    async updatePassword(_, payload) {
      return api.post('/profile/update', payload, formHeaders);
    },
    async recoverPassword({ dispatch }, payload) {
      const captcha = await dispatch('Auth/recaptcha', 'login', { root: true });
      const params = new URLSearchParams();

      params.append('username', payload.username);
      params.append('captcha', captcha);

      return api.post('/auth/optin/password/recover', params, formHeaders);
    },
    async validatePasswordRecoverHash(_, hash) {
      const params = new URLSearchParams();

      params.append('hash', hash);

      return api.post('/auth/optin/validate', params, formHeaders);
    },
    async changePasswordWithHash({ dispatch }, payload) {
      const captcha = await dispatch('Auth/recaptcha', 'login', { root: true });
      const params = new URLSearchParams();

      params.append('hash', payload.hash);
      params.append('password', payload.password);
      params.append('captcha', captcha);

      return api.post('/auth/optin/password/change', params, formHeaders);
    },
    async contactFetchDepartmentList(/* { commit } */) {
      const { data } = await api.get('/department/list');
      // commit("setContactDepartmentList", data)
      return data;
    },
    async contactDepartmentSubmit(_, payload) {
      const params = new URLSearchParams();

      params.append('department', payload.department);
      params.append('subject', payload.subject);
      params.append('text', payload.message);
      return api.post('/mailing/department/contact', params, formHeaders);
    },
  },
};
