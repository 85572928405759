import Api from "@/services/api/api/index.js"

const contact = args => {
  const params = new URLSearchParams()

  params.append("department", args.department)
  params.append("subject", args.subject)
  params.append("text", args.text)

  const req = Api.post("/mailing/department/contact", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })

  return req
}

export default {
  contact
}
