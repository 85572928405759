export default {
  get: function(){
    return {
      get:{
        fetching: false,
        error: false,
        success: false,
        github: [],
      },
      post:{
        posting: false,
        error: false,
        success: false
      }
    }
  }
}
