import myCache from './cache';
import storeCache from '@/services/api/cache'
import _ from 'underscore'

export default {
  contentMessageStatus(state){
    let cache = storeCache.get(myCache.MESSAGE_STORAGE_KEY);
    return _.has(cache, 'contentMessageStatus') ? cache.contentMessageStatus.value : state.contentMessageStatus;
  }
}
